import {ADD_CARD_TO_STACK, RESET_GAME, SET_DIFFICULTY_LEVEL, TAP} from './game-action-types'

export function tap(whoTapped) {
  return {
    type: TAP,
    payload: {
      whoTapped,
    },
  }
}

export function addCardToStack() {
  return {
    type: ADD_CARD_TO_STACK,
  }
}

export function resetGame() {
  return {
    type: RESET_GAME,
  }
}

export function setDifficultyLevel(difficultyLevel) {
  return {
    type: SET_DIFFICULTY_LEVEL,
    payload: {
      difficultyLevel,
    },
  }
}
