import React from 'react'
import '../css/components/home.scss'
import { connect } from 'react-redux'
import { SETTINGS_ROUTE, TUTORIAL_ROUTE } from '../routing/client-routes'
import { NavLink } from 'react-router-dom'
import { CardLine } from './card-line'

class Home extends React.Component {
  render() {
    return (
      <div className='main-menu'>
        <div className='top-card-line'>
          <CardLine />
        </div>
        <h1 className='main-menu__title'>
          <span role='img' aria-label='Great Britain flag'>
            🇬🇧
          </span>{' '}
          Battle of Corse{' '}
          <span role='img' aria-label='joker card'>
            🃏
          </span>{' '}
          Bataille Corse{' '}
          <span role='img' aria-label='French flag'>
            🇫🇷
          </span>
        </h1>
        <NavLink className='link' to={SETTINGS_ROUTE}>
          <h2>
            Play{' '}
            <span role='img' aria-label='play'>
              ▶
            </span>
            ️
          </h2>
        </NavLink>
        <NavLink className='link' to={TUTORIAL_ROUTE}>
          <h2>
            Tutorial{' '}
            <span role='img' aria-label='nerd face'>
              🤓
            </span>
          </h2>
        </NavLink>
        {/*<NavLink className='link' to={ABOUT_ROUTE}>*/}
        {/*  <h2>*/}
        {/*    About{' '}*/}
        {/*    <span role='img' aria-label='curious face'>*/}
        {/*      🤔*/}
        {/*    </span>*/}
        {/*  </h2>*/}
        {/*</NavLink>*/}
        <div className='bottom-card-line'>
          <CardLine />
        </div>
      </div>
    )
  }
}

const mapStateToProps = () => {
  return {}
}

export default connect(mapStateToProps, {})(Home)
