import {FIGURES, SUITS} from './constants'

export const mapFigureToValue = (figure) => {
  return {
    [FIGURES.TWO]: 2,
    [FIGURES.THREE]: 3,
    [FIGURES.FOUR]: 4,
    [FIGURES.FIVE]: 5,
    [FIGURES.SIX]: 6,
    [FIGURES.SEVEN]: 7,
    [FIGURES.EIGHT]: 8,
    [FIGURES.NINE]: 9,
    [FIGURES.TEN]: 10,
    [FIGURES.JACK]: 1,
    [FIGURES.QUEEN]: 2,
    [FIGURES.KING]: 3,
    [FIGURES.ACE]: 4,
  }[figure]
}

export const shuffle = (deck) => {
  const deckCopy = [...deck]
  let currentIndex = deckCopy.length, temporaryValue, randomIndex;

  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = deckCopy[currentIndex];
    deckCopy[currentIndex] = deckCopy[randomIndex];
    deckCopy[randomIndex] = temporaryValue;
  }

  return deckCopy;
}

export const getFullDeck = () => {
  const result = []
  const figures = Object.keys(FIGURES)
  const suits = Object.keys(SUITS)
  for (let i = 0; i < figures.length; i++) {
    const figure = figures[i];
    for (let j = 0; j < suits.length; j++) {
      const suit = suits[j];
      const card = {
        figure: figure,
        suit: suit,
      };
      result.push(card)
    }
  }
  return result
}

export const getFullShuffledDeck = () => {
  return shuffle(getFullDeck())
}
