import React from 'react'
import '../css/components/tutorial.scss'
import { connect } from 'react-redux'

class Tutorial extends React.Component {
  render() {
    return (
      <div className='tutorial'>
        <h1 className='tutorial__title'>
          Tutorial{' '}
          <span role='img' aria-label='nerd face'>
            🤓
          </span>
        </h1>
        <h1 className='tutorial__title'>Coming soon</h1>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps, {})(Tutorial)
