// this method was implemented by Kamil, might be wrong
export const randomDistribution = (mean, standardDeviation) => {
  let rand = 0

  for (let i = 0; i < 10; i += 1) {  // from central limit theorem
    rand += Math.random() - 0.5
  }

  return mean + (rand / Math.sqrt(12)) * Math.sqrt(10) * standardDeviation
}
