import React from 'react'
import '../css/components/table.scss'
import { connect } from 'react-redux'
import { StackCard } from './stack-card'
import StackClickArea from './stack-click-area'
import { tap } from '../actions/game/game-actions'
import { getCardsStack } from '../selectors/game-selectors'

const randomShiftFrom5To10 = () => {
  return Math.floor(Math.random() * 100) - 50
}

const randomAngle = () => {
  return Math.floor(Math.random() * 360)
}

const Table = ({ cardsStack }) => {
  return (
    <div className='stack'>
      {cardsStack.map((card) => (
        <StackCard
          card={card}
          key={JSON.stringify(card)}
          angle={randomAngle()}
          zIndex={1}
          left={randomShiftFrom5To10()}
          top={randomShiftFrom5To10()}
        />
      ))}
      <StackClickArea />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    cardsStack: getCardsStack(state),
  }
}

export default connect(mapStateToProps, {
  tap,
})(Table)
