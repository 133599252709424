import React from 'react'
import '../css/components/game-menu.scss'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { HOME_ROUTE } from '../routing/client-routes'
import {resetGame} from "../actions/game/game-actions";

const GameMenu = (props) => {
  const onClick = () => {
    props.resetGame()
  }

  return (
    <div className='game-menu'>
      <NavLink className='link' to={HOME_ROUTE} onClick={onClick}>
        <h2>
          Quit{' '}
          <span role='img' aria-label='door'>
            🚪
          </span>
        </h2>
      </NavLink>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps, { resetGame })(GameMenu)
