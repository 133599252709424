export const ABOUT_ROUTE = '/about'
export const GAME_ROUTE = '/game'
export const SETTINGS_ROUTE = '/settings'
export const TUTORIAL_ROUTE = '/tutorial'
export const HOME_ROUTE = '/'

export const EXTERNAL_LINKS = Object.freeze({
  TIENGOS_WEB: 'https://www.tiengos.com',
  DECLINATOR: 'https://www.declinator.com',
  TIENGOS_YOUTUBE_CHANNEL: 'https://www.youtube.com/c/tiengos',
  TIENGOS_PATREON_ACCOUNT: 'https://www.patreon.com/tiengos',
  FACEBOOK: 'https://www.facebook.com/kamil.nguyenvan.9',
  MEDIUM: 'https://medium.com/@kamilnguyenvan',
  LINKED_IN: 'https://www.linkedin.com/in/kamil-nguyen-van-91ab6985',
});
