import React from 'react'
import '../css/components/stack-click-area.scss'
import { connect } from 'react-redux'
import { tap } from '../actions/game/game-actions'
import {PLAYERS} from "../businesslogic/constants";

const StackClickArea = (props) => {
  const onClick = () => {
    props.tap(PLAYERS.PLAYER)
  }

  return <div className='stack-click-area' onClick={onClick}></div>
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps, {
  tap,
})(StackClickArea)
