import React from 'react'
import '../css/components/about.scss'
import { connect } from 'react-redux'
import {EXTERNAL_LINKS} from "../routing/client-routes";

class Home extends React.Component {
  render() {
    return (
      <div className='about'>
        <h1>About</h1>
        <h4>
          This online version of bataille corse has been created thanks to two people:
          <br />
          - Juliette Beaudet - who taught me the beautiful art of hand snapping
          <br />- Neta Betanhari - who reminded me of the beauty of it after many years had passed since I learnt it
        </h4>

        <h4>
          If you're interested in my more serious projects have a look at:
          <br />-{' '}
          <a target='_blank' rel='noopener noreferrer' href={EXTERNAL_LINKS.TIENGOS_WEB}>
            www.tiengos.com
          </a>{' '}
          - app for reading in foreign languages
          <br />
          - <a target='_blank' rel='noopener noreferrer' href={EXTERNAL_LINKS.DECLINATOR}>
            www.declinator.com
          </a>{' '}
          - online tool for helping with grammatical declensions
          <br />
          - <a target='_blank' rel='noopener noreferrer' href={EXTERNAL_LINKS.TIENGOS_YOUTUBE_CHANNEL}>
            my youtube channel
          </a>
          - where I teach random stuff
        </h4>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps, {})(Home)
