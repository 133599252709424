import card2C from '../img/2C.png'
import card2D from '../img/2D.png'
import card2H from '../img/2H.png'
import card2S from '../img/2S.png'

import card3C from '../img/3C.png'
import card3D from '../img/3D.png'
import card3H from '../img/3H.png'
import card3S from '../img/3S.png'

import card4C from '../img/4C.png'
import card4D from '../img/4D.png'
import card4H from '../img/4H.png'
import card4S from '../img/4S.png'

import card5C from '../img/5C.png'
import card5D from '../img/5D.png'
import card5H from '../img/5H.png'
import card5S from '../img/5S.png'

import card6C from '../img/6C.png'
import card6D from '../img/6D.png'
import card6H from '../img/6H.png'
import card6S from '../img/6S.png'

import card7C from '../img/7C.png'
import card7D from '../img/7D.png'
import card7H from '../img/7H.png'
import card7S from '../img/7S.png'

import card8C from '../img/8C.png'
import card8D from '../img/8D.png'
import card8H from '../img/8H.png'
import card8S from '../img/8S.png'

import card9C from '../img/9C.png'
import card9D from '../img/9D.png'
import card9H from '../img/9H.png'
import card9S from '../img/9S.png'

import card10C from '../img/10C.png'
import card10D from '../img/10D.png'
import card10H from '../img/10H.png'
import card10S from '../img/10S.png'

import cardJC from '../img/JC.png'
import cardJD from '../img/JD.png'
import cardJH from '../img/JH.png'
import cardJS from '../img/JS.png'

import cardQC from '../img/QC.png'
import cardQD from '../img/QD.png'
import cardQH from '../img/QH.png'
import cardQS from '../img/QS.png'

import cardKC from '../img/KC.png'
import cardKD from '../img/KD.png'
import cardKH from '../img/KH.png'
import cardKS from '../img/KS.png'

import cardAC from '../img/AC.png'
import cardAD from '../img/AD.png'
import cardAH from '../img/AH.png'
import cardAS from '../img/AS.png'

import {FIGURES, SUITS} from "../businesslogic/constants";
  let cardToImgMap = {
    [JSON.stringify({
      figure: FIGURES.TWO,
      suit: SUITS.CLUBS,
    })]: card2C,
    [JSON.stringify({
      figure: FIGURES.TWO,
      suit: SUITS.DIAMONDS,
    })]: card2D,
    [JSON.stringify({
      figure: FIGURES.TWO,
      suit: SUITS.HEARTS,
    })]: card2H,
    [JSON.stringify({
      figure: FIGURES.TWO,
      suit: SUITS.SPADES,
    })]: card2S,

    [JSON.stringify({
      figure: FIGURES.THREE,
      suit: SUITS.CLUBS,
    })]: card3C,
    [JSON.stringify({
      figure: FIGURES.THREE,
      suit: SUITS.DIAMONDS,
    })]: card3D,
    [JSON.stringify({
      figure: FIGURES.THREE,
      suit: SUITS.HEARTS,
    })]: card3H,
    [JSON.stringify({
      figure: FIGURES.THREE,
      suit: SUITS.SPADES,
    })]: card3S,

    [JSON.stringify({
      figure: FIGURES.FOUR,
      suit: SUITS.CLUBS,
    })]: card4C,
    [JSON.stringify({
      figure: FIGURES.FOUR,
      suit: SUITS.DIAMONDS,
    })]: card4D,
    [JSON.stringify({
      figure: FIGURES.FOUR,
      suit: SUITS.HEARTS,
    })]: card4H,
    [JSON.stringify({
      figure: FIGURES.FOUR,
      suit: SUITS.SPADES,
    })]: card4S,

    [JSON.stringify({
      figure: FIGURES.FIVE,
      suit: SUITS.CLUBS,
    })]: card5C,
    [JSON.stringify({
      figure: FIGURES.FIVE,
      suit: SUITS.DIAMONDS,
    })]: card5D,
    [JSON.stringify({
      figure: FIGURES.FIVE,
      suit: SUITS.HEARTS,
    })]: card5H,
    [JSON.stringify({
      figure: FIGURES.FIVE,
      suit: SUITS.SPADES,
    })]: card5S,

    [JSON.stringify({
      figure: FIGURES.SIX,
      suit: SUITS.CLUBS,
    })]: card6C,
    [JSON.stringify({
      figure: FIGURES.SIX,
      suit: SUITS.DIAMONDS,
    })]: card6D,
    [JSON.stringify({
      figure: FIGURES.SIX,
      suit: SUITS.HEARTS,
    })]: card6H,
    [JSON.stringify({
      figure: FIGURES.SIX,
      suit: SUITS.SPADES,
    })]: card6S,

    [JSON.stringify({
      figure: FIGURES.SEVEN,
      suit: SUITS.CLUBS,
    })]: card7C,
    [JSON.stringify({
      figure: FIGURES.SEVEN,
      suit: SUITS.DIAMONDS,
    })]: card7D,
    [JSON.stringify({
      figure: FIGURES.SEVEN,
      suit: SUITS.HEARTS,
    })]: card7H,
    [JSON.stringify({
      figure: FIGURES.SEVEN,
      suit: SUITS.SPADES,
    })]: card7S,

    [JSON.stringify({
      figure: FIGURES.EIGHT,
      suit: SUITS.CLUBS,
    })]: card8C,
    [JSON.stringify({
      figure: FIGURES.EIGHT,
      suit: SUITS.DIAMONDS,
    })]: card8D,
    [JSON.stringify({
      figure: FIGURES.EIGHT,
      suit: SUITS.HEARTS,
    })]: card8H,
    [JSON.stringify({
      figure: FIGURES.EIGHT,
      suit: SUITS.SPADES,
    })]: card8S,

    [JSON.stringify({
      figure: FIGURES.NINE,
      suit: SUITS.CLUBS,
    })]: card9C,
    [JSON.stringify({
      figure: FIGURES.NINE,
      suit: SUITS.DIAMONDS,
    })]: card9D,
    [JSON.stringify({
      figure: FIGURES.NINE,
      suit: SUITS.HEARTS,
    })]: card9H,
    [JSON.stringify({
      figure: FIGURES.NINE,
      suit: SUITS.SPADES,
    })]: card9S,

    [JSON.stringify({
      figure: FIGURES.TEN,
      suit: SUITS.CLUBS,
    })]: card10C,
    [JSON.stringify({
      figure: FIGURES.TEN,
      suit: SUITS.DIAMONDS,
    })]: card10D,
    [JSON.stringify({
      figure: FIGURES.TEN,
      suit: SUITS.HEARTS,
    })]: card10H,
    [JSON.stringify({
      figure: FIGURES.TEN,
      suit: SUITS.SPADES,
    })]: card10S,

    [JSON.stringify({
      figure: FIGURES.JACK,
      suit: SUITS.CLUBS,
    })]: cardJC,
    [JSON.stringify({
      figure: FIGURES.JACK,
      suit: SUITS.DIAMONDS,
    })]: cardJD,
    [JSON.stringify({
      figure: FIGURES.JACK,
      suit: SUITS.HEARTS,
    })]: cardJH,
    [JSON.stringify({
      figure: FIGURES.JACK,
      suit: SUITS.SPADES,
    })]: cardJS,

    [JSON.stringify({
      figure: FIGURES.QUEEN,
      suit: SUITS.CLUBS,
    })]: cardQC,
    [JSON.stringify({
      figure: FIGURES.QUEEN,
      suit: SUITS.DIAMONDS,
    })]: cardQD,
    [JSON.stringify({
      figure: FIGURES.QUEEN,
      suit: SUITS.HEARTS,
    })]: cardQH,
    [JSON.stringify({
      figure: FIGURES.QUEEN,
      suit: SUITS.SPADES,
    })]: cardQS,

    [JSON.stringify({
      figure: FIGURES.KING,
      suit: SUITS.CLUBS,
    })]: cardKC,
    [JSON.stringify({
      figure: FIGURES.KING,
      suit: SUITS.DIAMONDS,
    })]: cardKD,
    [JSON.stringify({
      figure: FIGURES.KING,
      suit: SUITS.HEARTS,
    })]: cardKH,
    [JSON.stringify({
      figure: FIGURES.KING,
      suit: SUITS.SPADES,
    })]: cardKS,

    [JSON.stringify({
      figure: FIGURES.ACE,
      suit: SUITS.CLUBS,
    })]: cardAC,
    [JSON.stringify({
      figure: FIGURES.ACE,
      suit: SUITS.DIAMONDS,
    })]: cardAD,
    [JSON.stringify({
      figure: FIGURES.ACE,
      suit: SUITS.HEARTS,
    })]: cardAH,
    [JSON.stringify({
      figure: FIGURES.ACE,
      suit: SUITS.SPADES,
    })]: cardAS,
  };

export const cardSymbolToImg = (symbol) => {
  return cardToImgMap[JSON.stringify(symbol)]
}
