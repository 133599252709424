import React from 'react'
import { hasComputerWon } from '../selectors/game-selectors'
import '../css/components/end-screen.css'
import { connect } from 'react-redux'

const EndScreen = ({ hasComputerWon }) => {
  return (
    <div className='end-game-screen'>
      <div className='end-game-screen__background' />
      <div className='end-game-screen__text'>{hasComputerWon ? '😢 Computer has won 😢' : '🎉 You won 🎉'}</div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    hasComputerWon: hasComputerWon(state),
  }
}

export default connect(mapStateToProps, {})(EndScreen)
