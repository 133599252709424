import './index.scss'
import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import {ABOUT_ROUTE, GAME_ROUTE, HOME_ROUTE, SETTINGS_ROUTE, TUTORIAL_ROUTE} from './routing/client-routes'
import Home from './components/home'
import About from './components/about'
import GameTable from './components/game'
import Tutorial from "./components/tutorial";
import Settings from "./components/settings";

export const Root = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={ABOUT_ROUTE} component={About} />
        <Route path={GAME_ROUTE} component={GameTable} />
        <Route path={TUTORIAL_ROUTE} component={Tutorial} />
        <Route path={SETTINGS_ROUTE} component={Settings} />
        <Route path={HOME_ROUTE} component={Home} />
      </Switch>
    </BrowserRouter>
  )
}
