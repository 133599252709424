import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import * as serviceWorker from './serviceWorker'
import store from './store'
import { Provider } from 'react-redux'
import { Root } from './root'
import * as firebase from 'firebase/app'
import 'firebase/analytics'
import { IS_PROD } from './config'

if (IS_PROD) {
  const firebaseConfig = {
    apiKey: 'AIzaSyBpQele4iMVCxUmOQyN3WwazmQeNVEdAYg',
    authDomain: 'battle-of-corsica.firebaseapp.com',
    databaseURL: 'https://battle-of-corsica.firebaseio.com',
    projectId: 'battle-of-corsica',
    storageBucket: 'battle-of-corsica.appspot.com',
    messagingSenderId: '707535491735',
    appId: '1:707535491735:web:10460e104fd9519f200fd2',
    measurementId: 'G-5C27W648P0',
  }
  firebase.initializeApp(firebaseConfig)
  firebase.analytics()
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Root />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
