import React, { Fragment } from 'react'
import '../css/components/game.scss'
import Table from './table'
import PlayerDeck from './decks/player-deck'
import { connect } from 'react-redux'
import ComputerDeck from './decks/computer-deck'
import { gameIsOn, hasComputerWon, hasPlayerWon } from '../selectors/game-selectors'
import EndScreen from './end-screen'
import GameMenu from './game-menu'

class Game extends React.Component {
  render() {
    return (
      <div className='game'>
        <Fragment>
          <Table />
          <ComputerDeck />
          <PlayerDeck />
          <GameMenu />
        </Fragment>
        {!this.props.gameIsOn && <EndScreen />}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    hasPlayerWon: hasPlayerWon(state),
    hasComputerWon: hasComputerWon(state),
    gameIsOn: gameIsOn(state),
  }
}

export default connect(mapStateToProps, {})(Game)
