import {applyMiddleware, combineReducers, createStore} from 'redux'
import { reducer as gameReducer } from './reducers/game-reducer'
import { IS_DEV } from './config'
import { createLogger } from 'redux-logger'

const middleware = []
if (IS_DEV) {
  const logger = createLogger({})
  middleware.push(logger)
}

export default createStore(combineReducers({ gameReducer: gameReducer }), {}, applyMiddleware(...middleware))
