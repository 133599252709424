import { ADD_CARD_TO_STACK, RESET_GAME, SET_DIFFICULTY_LEVEL, TAP } from '../actions/game/game-action-types'

import { createReducer } from '../utils/reducer-utils'
import { getFullShuffledDeck } from '../businesslogic/card-utils'
import { isTappable } from '../businesslogic/stack-calculator'
import { DIFFICULTY_LEVELS, PLAYERS } from '../businesslogic/constants'

export const initialStateGeneric = (cardsStack, computerCards, playerCards, turn) => {
  return {
    cardsStack: cardsStack,
    computerCards: computerCards,
    playerCards: playerCards,
    turn: turn,
    difficultyLevel: DIFFICULTY_LEVELS.NORMAL,
  }
}

const initialState = () => {
  const shuffledDeck = getFullShuffledDeck()
  const playerCards = shuffledDeck.filter((e, i) => i < 26)
  const computerCards = shuffledDeck.filter((e, i) => 26 <= i)
  return initialStateGeneric([], computerCards, playerCards, PLAYERS.PLAYER)
}

const actionsToStateChangesMap = {
  [RESET_GAME]: (state, p) => {
    return { ...initialState() }
  },
  [SET_DIFFICULTY_LEVEL]: (state, p) => {
    return {
      ...state,
      difficultyLevel: p.difficultyLevel,
    }
  },
  [TAP]: (state, p) => {
    if (p.whoTapped === PLAYERS.PLAYER) {
      if (isTappable(state.cardsStack)) {
        const newPlayerCards = []
        const newCardsStack = []
        for (let i = state.cardsStack.length - 1; i >= 0; i--) {
          const card = state.cardsStack[i]
          newPlayerCards.push(card)
        }
        for (let i = 0; i < state.playerCards.length; i++) {
          const card = state.playerCards[i]
          newPlayerCards.push(card)
        }
        return {
          ...state,
          playerCards: newPlayerCards,
          cardsStack: newCardsStack,
          turn: PLAYERS.PLAYER,
        }
      } else {
        const newCardsStack = [state.playerCards[state.playerCards.length - 1]].concat([...state.cardsStack])
        const newPlayerCards = [...state.playerCards]
        newPlayerCards.pop()
        return {
          ...state,
          playerCards: newPlayerCards,
          cardsStack: newCardsStack,
        }
      }
    } else {
      const newComputerCards = []
      const newCardsStack = []
      for (let i = state.cardsStack.length - 1; i >= 0; i--) {
        const card = state.cardsStack[i]
        newComputerCards.push(card)
      }
      for (let i = 0; i < state.computerCards.length; i++) {
        const card = state.computerCards[i]
        newComputerCards.push(card)
      }
      return {
        ...state,
        computerCards: newComputerCards,
        cardsStack: newCardsStack,
        turn: PLAYERS.COMPUTER,
      }
    }
  },
  [ADD_CARD_TO_STACK]: (state) => {
    const newCardsStack = [...state.cardsStack]
    const newPlayerCards = [...state.playerCards]
    const newComputerCards = [...state.computerCards]
    if (state.turn === PLAYERS.PLAYER) {
      newCardsStack.push(state.playerCards[state.playerCards.length - 1])
      newPlayerCards.splice(newPlayerCards.length - 1, 1)
    } else {
      newCardsStack.push(state.computerCards[state.computerCards.length - 1])
      console.log(newCardsStack[newCardsStack.length - 1])
      newComputerCards.splice(newComputerCards.length - 1, 1)
    }
    return {
      ...state,
      cardsStack: newCardsStack,
      playerCards: newPlayerCards,
      computerCards: newComputerCards,
      turn: state.turn === PLAYERS.PLAYER ? PLAYERS.COMPUTER : PLAYERS.PLAYER,
    }
  },
}

export const reducer = createReducer(actionsToStateChangesMap, initialState())
