import React, { useState } from 'react'
import '../css/components/card-line.scss'
import { getFullShuffledDeck } from '../businesslogic/card-utils'
import { cardSymbolToImg } from '../utils/card-to-img-mapper'

const Card = ({ card }) => {
  return (
    <div className='card-line__card'>
      <img className='card-line__card__img' src={cardSymbolToImg(card)} alt='playing card' />
    </div>
  )
}

export const CardLine = (props) => {
  const [cards] = useState(getFullShuffledDeck())

  return (
    <div className='card-line'>
      {cards.map((c) => (
        <Card key={JSON.stringify(c)} card={c} />
      ))}
    </div>
  )
}
