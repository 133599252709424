import React from 'react'
import '../css/components/stack-card.scss'
import { cardSymbolToImg } from '../utils/card-to-img-mapper'

export class StackCard extends React.Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return JSON.stringify(nextProps.card) !== JSON.stringify(this.props.card) || nextProps.zIndex !== this.props.zIndex
  }

  render() {
    const { card, angle, zIndex, left, top } = this.props
    return (
      <div className='stack-card' style={{ transform: `translateX(${-50 + left}%) translateY(${-50 + top}%)` }}>
        <div
          className='stack-card__content'
          style={{
            transform: `rotate(${angle}deg)`,
            top: '50%',
            left: '50%',
            zIndex: `${zIndex}`,
          }}
        >
          <img className='stack-card__content-image' src={cardSymbolToImg(card)} alt='playing card' />
        </div>
      </div>
    )
  }
}
