import { mapFigureToValue } from './card-utils'

export const isTappable = (cardsStack) => {
  function isTappableBecauseOfMostRecentCardsSummingUpTo10() {
    let sum = 0
    for (let i = cardsStack.length - 1; i >= 0; i--) {
      sum += mapFigureToValue(cardsStack[i].figure)
      if (sum > 10) {
        break
      }
      if (sum === 10) {
        return true
      }
    }
    return false
  }
  function isTappableBecauseOf2MostRecentCardsBeingTheSame() {
    if (cardsStack.length > 1) {
      return cardsStack[cardsStack.length - 1].figure === cardsStack[cardsStack.length - 2].figure
    }
    return false
  }
  function isTappableBecauseOf1stAnd3rdCardsBeingTheSame() {
    if (cardsStack.length > 2) {
      return cardsStack[cardsStack.length - 1].figure === cardsStack[cardsStack.length - 3].figure
    }
    return false
  }
  return (
    isTappableBecauseOfMostRecentCardsSummingUpTo10() ||
    isTappableBecauseOf2MostRecentCardsBeingTheSame() ||
    isTappableBecauseOf1stAnd3rdCardsBeingTheSame()
  )
}
