import React from 'react'
import '../../css/components/decks/decks.scss'
import { connect } from 'react-redux'
import { addCardToStack, tap } from '../../actions/game/game-actions'
import {
  getComputerReactionTime,
  getNumberOfPlayerCards,
  isComputerTurn,
  isPlayerTurn,
  isTappable,
} from '../../selectors/game-selectors'
import { DeckWithCounter } from './deck-with-counter'
import { PLAYERS } from '../../businesslogic/constants'
import { randomDistribution } from '../../utils/custom-math'

class PlayerDeck extends React.Component {
  onClick = () => {
    if (this.props.isPlayerTurn) {
      this.props.addCardToStack()

      const tryToTapAndThenAddCard = () => {
        if (this.props.isTappable) {
          this.props.tap(PLAYERS.COMPUTER)
          const timeout = randomDistribution(this.props.computerReactionTime, this.props.computerReactionTime / 7)
          setTimeout(function () {
            tryToTapAndThenAddCard()
          }, timeout)
          setTimeout(function () {
            tryToTapAndThenAddCard()
          }, 2 * timeout)
        }
        if (this.props.isComputerTurn) {
          this.props.addCardToStack()
        }
      }
      const timeout = randomDistribution(this.props.computerReactionTime, this.props.computerReactionTime / 7)
      setTimeout(function () {
        tryToTapAndThenAddCard()
      }, timeout)
      setTimeout(function () {
        tryToTapAndThenAddCard()
      }, 2 * timeout)
    }
  }

  render() {
    return (
      <div className='player-deck' onClick={this.onClick}>
        <DeckWithCounter counter={this.props.numberOfPlayerCards} />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    numberOfPlayerCards: getNumberOfPlayerCards(state),
    isPlayerTurn: isPlayerTurn(state),
    computerReactionTime: getComputerReactionTime(state),
    isComputerTurn: isComputerTurn(state),
    isTappable: isTappable(state),
  }
}

export default connect(mapStateToProps, {
  addCardToStack,
  tap,
})(PlayerDeck)
