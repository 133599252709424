import React from 'react'
import '../../css/components/decks/decks.scss'
import { connect } from 'react-redux'
import { getNumberOfComputerCards } from '../../selectors/game-selectors'
import { DeckWithCounter } from './deck-with-counter'

class ComputerDeck extends React.Component {
  render() {
    return (
      <div className='computer-deck'>
        <DeckWithCounter counter={this.props.numberOfComputerCards} />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    numberOfComputerCards: getNumberOfComputerCards(state),
  }
}

export default connect(mapStateToProps, {})(ComputerDeck)
