export const SUITS = Object.freeze({
  SPADES: 'SPADES',
  DIAMONDS: 'DIAMONDS',
  CLUBS: 'CLUBS',
  HEARTS: 'HEARTS',
})

export const FIGURES = Object.freeze({
  TWO: 'TWO',
  THREE: 'THREE',
  FOUR: 'FOUR',
  FIVE: 'FIVE',
  SIX: 'SIX',
  SEVEN: 'SEVEN',
  EIGHT: 'EIGHT',
  NINE: 'NINE',
  TEN: 'TEN',
  JACK: 'JACK',
  QUEEN: 'QUEEN',
  KING: 'KING',
  ACE: 'ACE',
})

export const PLAYERS = Object.freeze({
  PLAYER: 'PLAYER',
  COMPUTER: 'COMPUTER',
})

export const DIFFICULTY_LEVELS = Object.freeze({
  AMATEUR: 'AMATEUR',
  NORMAL: 'NORMAL',
  ADVANCED: 'ADVANCED',
  HARD: 'HARD',
  NIGHTMARE: 'NIGHTMARE',
})

export const LEVEL_TO_COMPUTER_REACTION_TIME = Object.freeze({
  [DIFFICULTY_LEVELS.AMATEUR]: 5000,
  [DIFFICULTY_LEVELS.NORMAL]: 1500,
  [DIFFICULTY_LEVELS.ADVANCED]: 800,
  [DIFFICULTY_LEVELS.HARD]: 500,
  [DIFFICULTY_LEVELS.NIGHTMARE]: 300,
})
