import React from 'react'
import '../css/components/settings.scss'
import { connect } from 'react-redux'
import { GAME_ROUTE, HOME_ROUTE } from '../routing/client-routes'
import { NavLink } from 'react-router-dom'
import { getDifficultyLevel } from '../selectors/game-selectors'
import { DIFFICULTY_LEVELS } from '../businesslogic/constants'
import { setDifficultyLevel } from '../actions/game/game-actions'

const Settings = (props) => {
  const onClick = (level) => {
    props.setDifficultyLevel(level)
  }
  const getRadioButton = function (level, text, emoji) {
    return (
      <div className='difficulty-levels__level' onClick={() => onClick(level)}>
        <input type='radio' className='difficulty-radio' name='level' checked={props.currentLevel === level} readOnly />{' '}
        <h3>
          {text}{' '}
          <span role='img' aria-label='devil'>
            {emoji}
          </span>
        </h3>
      </div>
    )
  }
  return (
    <div className='settings'>
      <h1 className='settings__title'>
        Settings{' '}
        <span role='img' aria-label='cog wheel'>
          ⚙
        </span>
        ️
      </h1>
      <div className='difficulty-levels'>
        <h2>
          difficulty:
        </h2>
        {getRadioButton(DIFFICULTY_LEVELS.AMATEUR, 'Amateur', '👶')}
        {getRadioButton(DIFFICULTY_LEVELS.NORMAL, 'Normal', '😐')}
        {getRadioButton(DIFFICULTY_LEVELS.ADVANCED, 'Advanced', '🤠')}
        {getRadioButton(DIFFICULTY_LEVELS.HARD, 'Hard', '😎')}
        {getRadioButton(DIFFICULTY_LEVELS.NIGHTMARE, 'Nightmare', '😈')}
      </div>
      <NavLink className='link next-link' to={GAME_ROUTE}>
        <h2>
          Play{' '}
          <span role='img' aria-label='play'>
            ▶
          </span>
          ️
        </h2>
      </NavLink>
      <NavLink className='link previous-link' to={HOME_ROUTE}>
        <h2>
          <span role='img' aria-label='back arrow'>
            ⬅
          </span>
        </h2>
      </NavLink>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    currentLevel: getDifficultyLevel(state),
  }
}

export default connect(mapStateToProps, {
  setDifficultyLevel,
})(Settings)
