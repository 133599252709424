import {LEVEL_TO_COMPUTER_REACTION_TIME, PLAYERS} from "../businesslogic/constants";
import * as stackCalculator from '../businesslogic/stack-calculator'

export function getGameState(state) {
  return state.gameReducer
}

export function getCardsStack(state) {
  return getGameState(state).cardsStack
}

export function getPlayerCards(state) {
  return getGameState(state).playerCards
}

export function getComputerCards(state) {
  return getGameState(state).computerCards
}

export function getTurn(state) {
  return getGameState(state).turn
}

export function isComputerTurn(state) {
  return getGameState(state).turn === PLAYERS.COMPUTER
}

export function isPlayerTurn(state) {
  return getGameState(state).turn === PLAYERS.PLAYER
}

export function hasPlayerWon(state) {
  return getGameState(state).computerCards.length === 0
}

export function hasComputerWon(state) {
  return getGameState(state).playerCards.length === 0
}

export function gameIsOn(state) {
  return !hasPlayerWon(state) && !hasComputerWon(state)
}

export function getNumberOfPlayerCards(state) {
  return getPlayerCards(state).length
}

export function getNumberOfComputerCards(state) {
  return getComputerCards(state).length
}

export function isTappable(state) {
  return stackCalculator.isTappable(getCardsStack(state))
}

export function getDifficultyLevel(state) {
  return getGameState(state).difficultyLevel
}

export function getComputerReactionTime(state) {
  return LEVEL_TO_COMPUTER_REACTION_TIME[getGameState(state).difficultyLevel]
}
