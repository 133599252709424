import React from 'react'
import '../../css/components/decks/decks.scss'
import cardReverse from '../../img/card-reverse.png'

export const DeckWithCounter = ({ counter }) => {
  return (
    <div className='deck'>
      <img className='deck__image' src={cardReverse} alt='card reverse' />
      <div className='deck__counter'>{counter}</div>
    </div>
  )
}
